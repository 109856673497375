import React, {useRef} from 'react';
import { Container, Text, Button, useMantineTheme, Title} from '@mantine/core';
import Header from './components/Header';
import FeatureCarousel from './components/FeatureCarousel';
import ContactForm from './components/ContactForm';
import { useMediaQuery  } from '@mantine/hooks';

function App() {

    const theme = useMantineTheme()
    const isTabletOrPhone = useMediaQuery('(max-width: 1224px)');
    /* Main Content */
    const titleFontSize = isTabletOrPhone ? '2.5rem' : '4.5rem';
    const textFontSize = isTabletOrPhone ? '18px' : '24px';
    const containerHeight = isTabletOrPhone ? '460px' : '660px';
    /* Sub Content */
    const headingFontSize = isTabletOrPhone ? '24px' : '32px';
    const subTextFontSize = isTabletOrPhone ? '16px' : '18px';
    const containerPadding = isTabletOrPhone ? '2rem 2rem' : '8rem 10rem';
    const maxWidth = isTabletOrPhone ? '' : '64rem';
    const textAlignment = isTabletOrPhone? 'center' : 'left';
    const boxHeight = isTabletOrPhone? '700px' : '600px';

    const contactRef = useRef(null)
    const aboutUsRef = useRef(null)

    const handleScrollToAboutUs = () => {
        aboutUsRef.current.scrollIntoView({ behavior: 'smooth' });
      };

    return (
        <Container fluid>
            {/* Header */}
            <Header contactRef={contactRef} isTabletOrPhone={isTabletOrPhone} /> 

            {/* Main Content */}
            <div style={{ height: containerHeight, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Title align="center" size="xl" style={{fontSize: titleFontSize }}>Demistifying the EHCP <br/>Powered by Generative AI</Title>
                <br/>
                <Text align="center" style={{fontSize: textFontSize }}>Query the advice and guidance from gov.uk documentation using<br/> the latest ChatGPT models</Text>
                <br/>
                <Button onClick={handleScrollToAboutUs}>Learn More</Button>
            </div>
            {/* Sub Content */}
            <div ref={aboutUsRef} style={{ height: boxHeight, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: containerPadding, backgroundColor: theme.colors.blue[6] }}>
                <Text align={textAlignment} style={{fontSize: headingFontSize, color: 'white'}}>About Us</Text>
                <br/>
                <Text align={textAlignment} style={{fontSize: subTextFontSize, color: 'white'}}>Introducing Chat-EHCP! 🎉 A new way of navigating the UK EHCP process, for children with Special Educational Needs.</Text>
                <br/>
                <Text span align={textAlignment} style={{maxWidth: maxWidth, fontSize: subTextFontSize, color: 'white'}}>Harness the power of cutting-edge Artificial Intelligence as we sift through
                 the latest government documents, ensuring you get clear and accurate answers tailored for your child. As parents ourselves we've battled the complexities of the
                  EHCP maze ourselves and recognize the overwhelming nature of available information.</Text>
                <br/>
                <Text span align={textAlignment} style={{maxWidth: maxWidth, fontSize: subTextFontSize, color: 'white'}}>Our mission? Simplify the process! We bring you an efficient way to explore 
                up-to-date resources, offering essential tools to craft emails and review personal EHCP statements. While we don't provide direct advice, we equip you with the
                 best of existing guidance, all at your fingertips.</Text>
            </div>

            {/* Carousel */}
            <FeatureCarousel isTabletOrPhone={isTabletOrPhone} /> 
            <div ref={contactRef} >
                <ContactForm isTabletOrPhone={isTabletOrPhone}/>
            </div>

        </Container>
    );
}

export default App;
