import React, { useState } from 'react';
import { Paper, useMantineTheme, TextInput, Textarea, Button, Text } from '@mantine/core';

function ContactForm(props) {
    const { isTabletOrPhone } = props
    const theme = useMantineTheme()
    const flexDirection = isTabletOrPhone ? 'column' : 'row';
    const formPadding = isTabletOrPhone ? '4rem 2rem' : '8rem 10rem';
    const inputContainerMargin = isTabletOrPhone ? '0px 0px 15px 0px' : '15px 0';
    const sectionFlex = isTabletOrPhone ? '1' : '0 48%';

    // Initialize state variables
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [serverMessage, setServerMessage] = useState(null);


    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior

        const formData = {
        name,
        email,
        message
        };

        // Log or process form data
        console.log(formData);

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/contactme`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
    });

    if (response.ok) {
        setServerMessage("Thank you for leaving a message!");
    } else {
        alert("There was an error sending the message. Please try again later");
    }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Paper padding="lg" style={{ margin: '20px 0', padding: formPadding, marginLeft: 'auto', marginRight: 'auto', backgroundColor: theme.colors.blue[1] }}>
            <div style={{ display: 'flex', flexDirection: flexDirection, justifyContent: 'space-between' }}>
                
                {/* Right Section - Header and Additional Text */}
                <div style={{ flex: sectionFlex, marginBottom: isTabletOrPhone ? '15px' : '0' }}>
                    <Text  style={{fontSize: '32px'}}>Contact Us</Text>
                    <br/>
                    <Text  style={{fontSize: '18px'}}>We'd love to know what kind of questions or tasks you would set Chat-EHCP.
                    Please drop us a line and we'll get back to you ASAP</Text>
                    <br />
                    {serverMessage && <Text style={{ color: 'blue', fontSize: '16px' }}>{serverMessage}</Text>}
                </div>


                {/* Left Section - Input Fields and Button */}
                <div style={{ flex: sectionFlex, marginRight: isTabletOrPhone ? '0' : '2%', marginBottom: inputContainerMargin }}>
                    <div style={{ display: 'flex', flexDirection: flexDirection, justifyContent: 'space-between', marginBottom: '15px' }}>
                        <TextInput
                            label="Name"
                            size="lg"
                            placeholder="Enter your name"
                            style={{ flex: '0 48%', marginRight: '2%', marginBottom: inputContainerMargin }}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextInput
                            label="Email Address"
                            size="lg"
                            placeholder="Enter your email"
                            type="email"
                            style={{ flex: '0 48%', marginBottom: inputContainerMargin }}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <Textarea
                        label="Message"
                        size="lg"
                        placeholder="Your message..."
                        autosize
                        minRows={3}
                        style={{ marginBottom: '15px', width: '100%' }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <Button type="submit">
                        Submit
                    </Button>
                </div>
            </div>
        </Paper>
      </form>
    );
}

export default ContactForm