import React from 'react';
import { createRoot } from 'react-dom/client'
import App from './App';
import './index.css';
import { MantineProvider, createTheme } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';

const theme = createTheme({
  fontFamily: "'Exo 2', sans-serif",
  primaryColor: 'blue',
});

function Root() {
  return (
      <MantineProvider theme={theme} withGlobalStyles>
          <App />
      </MantineProvider>
  );
}

// This line renders the Root component into the DOM
const container = document.getElementById('root');
const root = createRoot(container)
root.render(<Root />)
