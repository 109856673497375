
import React from 'react';
import { Carousel } from '@mantine/carousel'
import { Paper, Title, Button } from '@mantine/core';
import './FeatureCarousel.css';
import mailImage from '../assets/mobile.jpg';
import phoneImage from '../assets/email-image.jpg'
import reportImage from '../assets/report.jpg'
import meetingImage from '../assets/meeting.jpg'

const data = [
  {
    image: phoneImage,
    title: "Chat-EHCP on your phone",
    colour: "white",
    description: "Coming Soon!"
  },
  {
    image: mailImage,
    title: "Reply to EHCP mails with confidence",
    colour: "white",
    description: "Coming Soon!"
  },
  {
    image: reportImage,
    title: "Critique and improve your EHCP report",
    colour: "white",
    description: "Coming Soon!"
  },
  {
    image: meetingImage,
    title: "Prepare for meetings with confidence",
    colour: "white",
    description: "Coming Soon!"
  }
];

function Card(props) {
  const { image, title, description, colour, isTabletOrPhone } = props;
  const cardHeight = isTabletOrPhone ? '300px' : '500px';
  const cardWidth = isTabletOrPhone ? '100%' : '490px';
  
  return (
    <Paper
      shadow="md"
      p="xl"
      radius="md"
      style={{ height: cardHeight, 
               width: cardWidth, 
               backgroundImage: `url(${image})`, 
               display: 'flex',         // Flex container
               flexDirection: 'column',
               justifyContent: 'flex-end', // Push content to the bottom
               alignItems: 'flex-end'}}
    >
      <div>
        <Title order={3} style={{ color: colour, padding: '10px 0px'}} >
          {title}
        </Title>
      </div>
      <Button variant="white" color="dark">
        {description}
      </Button>
    </Paper>
  );
}

function FeatureCarousel(props) {
  const { isTabletOrPhone } = props;
  const slideSize = isTabletOrPhone ? '100%' : '33.333333%';
  const slidesToScroll = isTabletOrPhone ? 1 : 2;
  const carouselPadding = isTabletOrPhone ? '2rem 1rem' : '4rem 4rem';
  const carouselHeight = isTabletOrPhone ? '300' : '500';

    const slides = data.map((item) => (
      <Carousel.Slide key={item.title}>
        <Card {...item} isTabletOrPhone={isTabletOrPhone} />
      </Carousel.Slide>
    ));

    return (
    <Carousel
    withIndicators
    height={carouselHeight}
    slideSize={slideSize}
    slideGap="md"
    loop
      align="start"
      slidesToScroll={slidesToScroll}
      style={{padding: carouselPadding}}
    >
       {slides}
    </Carousel>
    );
}

export default FeatureCarousel