// components/Header.js

import React from 'react';
import { Paper, Text, Image, Button } from '@mantine/core';
//import { IconBrandFacebook, IconBrandX, IconBrandInstagram, IconAt } from '@tabler/icons-react'
import { useMantineTheme } from '@mantine/core';
import logoImage from '../assets/logo.png';

function Header(props) {

    const {contactRef, isTabletOrPhone} = props
    const theme = useMantineTheme()
    const headerHeight = isTabletOrPhone ? '80px' : '96px'; // Adjust height for mobile
    const logoMargin = isTabletOrPhone ? '5px' : '10px';
    const fontSize = isTabletOrPhone ? '22px' : '32px'    // Adjust logo margin for mobile
  

    const handleScrollToContact = () => {
        contactRef.current.scrollIntoView({ behavior: 'smooth' });
      };

    



    return (
        <Paper padding="md"  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: headerHeight, backgroundColor: theme.colors.blue[6] }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* Replace with your actual logo */}
                <Image src={logoImage} alt="Logo" width={40} height={40} style={{ marginRight: logoMargin }} />
                <Text size="xl" style={{ fontSize: fontSize, color: theme.colors.blue[0] }}>Chat-EHCP</Text>
            </div>
            <div style={{ display: 'flex', gap: '15px', marginRight: isTabletOrPhone ? '10px' : '20px' }}>   {/* Adjust marginRight for mobile */}
                <Button variant="primary" onClick={handleScrollToContact} style={{ backgroundColor: theme.colors.blue[8], color: theme.colors.blue[0]}}>Contact Us</Button>
           {  /*   <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><IconBrandFacebook style={{ color: 'white', paddingTop: "8px" }} /></a> */}
           {  /*   <a href="#" target="_blank" rel="noopener noreferrer"><IconBrandX style={{ color: 'white', paddingTop: "8px" }} /></a>  */}
           {  /*   <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><IconBrandInstagram style={{ color: 'white', paddingTop: "8px" }} /></a> */}
            </div>
        </Paper>
    );
}

export default Header;
